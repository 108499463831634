<template>
  <div class="box-shaddow20">
    <header
      class="container d-flex align-items-center justify-content-between px-lg-1 px-3 py-2 py-md-0"
    >
      <div
        class="d-lg-flex d-none align-items-center justify-content-start flex-grow-1"
      >
        <MegaMeun />
      </div>

      <div
        class="d-lg-flex d-none align-items-center justify-content-center flex-grow-1 py-4 py-lg-2 logo-header"
      >
        <Logo />
      </div>

      <div
        class="d-flex d-lg-none align-items-center justify-content-start respLogo"
      >
        <button
          type="button"
          id="all-categories"
          class="all-categories bg-transparent resp-menu-btn d-lg-none"
          @click="$emit('openRespMenu')"
        >
          <MenuBar />
        </button>
        <Logo />
      </div>
      <div
        class="d-flex align-items-center justify-content-end flex-grow-1 header-icons"
      >
        <button
          class="bg-transparent"
          type="button"
          @click="showCloseSearchBox()"
        >
          <searchSvg />
        </button>

        <button class="bg-transparent" type="button" @click="cartListsOpen()">
          <CartButton />
        </button>
        <LoginUser :atlasTheme="true" />
      </div>
    </header>
  </div>
</template>

<script>
import searchSvg from "./svg/Search.vue";
import CartButton from "./cart/CartButton.vue";
import { cartListsOpen, openSearch } from "@/libraries/functions";
import Logo from "@/parts/Front/components/AtlasLogo";
import MegaMeun from "../components/MegaMenu.vue";
import LoginUser from "@/parts/Front/components/LoginUser";
import UserSvg from "./svg/User.vue";
import MenuBar from "./svg/MenuBar.vue";
export default {
  components: {
    searchSvg,
    Logo,
    MegaMeun,
    CartButton,
    LoginUser,
    UserSvg,
    MenuBar,
  },
  computed: {
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
  methods: {
    cartListsOpen() {
      cartListsOpen();
      this.$router.push(this.$route.fullPath + "#cart-modal");
    },
    showCloseSearchBox() {
      openSearch();
    },
  },
};
</script>

<style scoped>
.header-icons button {
  padding: 0 12px 0 0;
}
.logo-header {
  transform: translateX(50%);
  position: absolute;
  right: 50%;
}
</style>
<style>
@media (max-width: 580px) {
  .respLogo {
    flex-grow: 1;
    justify-content: space-between !important;
    width: 50% !important;
  }
  .respLogo .parent-logo {
    margin: unset !important;
  }
}
</style>
